<template>
  <v-container fluid></v-container>
</template>

<script>
export default {
  name: "PmsDashboardComponent"
}
</script>

<style scoped>

</style>
