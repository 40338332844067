<template>
  <div>
    <page-title :heading="$t('generic.lang_dashboard')" :icon=icon :subheading="$t('generic.lang_dashboard')"
    ></page-title>
    <div class="app-main__inner">
      <pms-dashboard-component/>
    </div>
  </div>
</template>
<script>
import PageTitle from "@/Layout/Components/PageTitle";
import PmsDashboardComponent from "@/components/pms/PmsDashboardComponent";


export default {
  name: "PmsDashboardView",
  components: {
    PmsDashboardComponent,
    PageTitle,
  },
  data: () => ({
    icon: 'pe-7s-graph icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>
